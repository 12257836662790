@tailwind base;
@tailwind components;
@tailwind utilities;

@import './app/shared/styles/theme.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  min-width: 350px;
}

.disabled_elem,
.read_only {
  opacity: 1;
  pointer-events: none;
  cursor: not-allowed;
}

.disabled_elem {
  opacity: 0.45;
}

.hidden_elem {
  height: 0 !important;
  overflow: hidden;
  padding: 0 !important;
}

td,
th {
  padding: 5px 10px;
  border: 1px solid #cccccc;
  text-align: left;
}